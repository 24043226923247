import { AuthService } from 'src/app/auth/auth.service';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { ProgressReportQuarter } from 'leetify-shared-utils/enums';
import { Router, Scroll } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { environment } from 'src/environments/environment';
import { ProgressReportsHelper } from 'leetify-shared-utils/progress-reports-helper';
import { ViewHelper } from 'src/app/helpers/view.helper';

interface AvailableProgressReportViewModel {
  routerLink: (string | number)[];
  title: string;
  href: string;
}

interface AvailableProgressReportsYearViewModel {
  heading: string | number;
  reports: AvailableProgressReportViewModel[];
}

@Component({
	selector: 'leetify-common-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	host: {
		'(document:click)': 'onClick($event)',
	},
})
export class CommonUserMenuComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly ngUnsubscribe = new Subject<void>();

	public readonly onProfilePictureErrored = ViewHelper.onProfilePictureErrored;

	protected availableAnnualRecaps: AvailableProgressReportViewModel[];
	protected availableProgressReports: AvailableProgressReportsYearViewModel[];
	protected isAnnualRecapsSubmenuExpanded = false;
	protected isMenuActive = false;
	protected isProgressReportsSubmenuExpanded = false;
	protected profilePictureUrl: string;
	protected user: User;
	protected displayName: string;
	protected currentApp: string;

	protected baseUrlApp = `${environment.csFrontendBaseUrl}/app`;
	protected baseUrlHome = `${environment.frontendBaseUrl}/home`;

	public constructor(
    protected readonly _eref: ElementRef,
    protected readonly authService: AuthService,
    protected readonly router: Router,
    protected readonly userService: UserService,
	) {
		//
	}

	protected onClick(e: PointerEvent): void {
		if (!this._eref.nativeElement.contains(e.target)) {
			// or some similar check
			this.toggleDropdown(false);
		}
	}

	protected toggleDropdown(isActive = !this.isMenuActive): void {
		this.isMenuActive = isActive;

		if (!isActive) {
			this.toggleAnnualRecapsSubmenu(false);
			this.toggleProgressReportsSubmenu(false);
		}
	}

	protected toggleAnnualRecapsSubmenu(isExpanded = !this.isAnnualRecapsSubmenuExpanded): void {
		this.isAnnualRecapsSubmenuExpanded = isExpanded;
	}

	protected toggleProgressReportsSubmenu(isExpanded = !this.isProgressReportsSubmenuExpanded): void {
		this.isProgressReportsSubmenuExpanded = isExpanded;
	}

	protected logout(): void {
		this.authService.logout();
		window.location.href = '/';
	}

	protected handleUser(user: User): void {
		this.user = user;
		this.setAvailableProgressReports();

		// Grab route
		const path = window.location.pathname;

		// TODO: SHARED-TOP-NAV Fix before launch: use Leetify-wide nickname regardless of path
		if (path.startsWith('/app')) {
			// @ts-ignore - Will be consolidated before launch
			this.displayName = user.nickname || user.steamNickname || user.faceitNickname;
		} else if (path.startsWith('/lol')) {
			// @ts-ignore - See above
			this.displayName = user.nickname || `${user.userName}#${user.userTag}`;
		} else if (path.startsWith('/home')) {
			// @ts-ignore - See above
			this.displayName = user.nickname || user.steamNickname || user.faceitNickname;
		}

    // @ts-ignore - home uses leetifyUserId, LoL and CS uses id
		this.profilePictureUrl = this.userService.getProfilePictureUrl(user.leetifyUserId || user.id);
	}

	public goToPage(location: string) {
		window.location.href = `${environment.csFrontendBaseUrl}/app/${location}`;
	}

	public goToPageHome(location: string) {
		window.location.href = `${environment.frontendBaseUrl}/home/${location}`;
	}

	protected goToProfile() {
		window.location.href = `${environment.csFrontendBaseUrl}/app/profile/${this.user.steam64Id || ''}`;
	}

	protected setAvailableProgressReports(): void {
		// @ts-expect-error - LoL uses user.id instead of user.leetifyUserId
		const id = this.user.id || this.user.leetifyUserId;

		const reports = ProgressReportsHelper.getAvailableProgressReports({
			steam64Id: this.user.steam64Id,
			userCreatedAt: new Date(this.user.signUpDate),
			userId: id,
		});

		const annualRecaps: AvailableProgressReportViewModel[] = [];
		const progressReports: AvailableProgressReportsYearViewModel[] = [];

		for (const report of reports) {
			const link = ProgressReportsHelper.getUrlSegments(report);

			let href = '';

			if (this.currentApp !== 'cs') {
				href = link.join('/');
			}

			const reportViewModel: AvailableProgressReportViewModel = {
				routerLink: ProgressReportsHelper.getUrlSegments(report),
				title: ProgressReportsHelper.getProgressReportTitleWithoutNickname(report.year, report.quarter),
				href,
			};

			if (report.quarter === ProgressReportQuarter.ANNUAL) {
				annualRecaps.push(reportViewModel);
			} else {
				if (!progressReports.length || progressReports[progressReports.length - 1].heading !== report.year) {
					progressReports.push({ heading: report.year, reports: [] });
				}

				progressReports[progressReports.length - 1].reports.push(reportViewModel);
			}
		}

		this.availableAnnualRecaps = annualRecaps;
		this.availableProgressReports = progressReports;
	}

	public ngOnInit(): void {
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));
		this.handleUser(this.userService.user);

		// close menu after clicking any link
		this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(() => this.toggleDropdown(false));

		const path = window.location.pathname;

		if (path.startsWith('/app')) {
			this.currentApp = 'cs';
		}
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
