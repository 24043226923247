import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppLayoutComponent } from 'src/app/layouts/app-layout/app-layout.component';
import { AuthTokenCallbackComponent } from './auth/auth.token-callback';
import { AuthGuard } from './auth/auth.guard';
import { ReferralRedirectComponent } from './pages/user/redirect/referral-redirect.component';

const routes: Routes = [
    { path: '', redirectTo: '', pathMatch: 'full' },
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', loadChildren: () => import('src/app/pages/user/matches/matches.module').then((m) => m.MatchesModule) },
            { path: 'match-details/:id', loadChildren: () => import('src/app/pages/user/match-details/match-details.module').then((m) => m.MatchDetailsModule) },
            { path: 'matches', loadChildren: () => import('src/app/pages/user/matches/matches.module').then((m) => m.MatchesModule) },
        ],
    },
    { path: 'invite/:code', component: ReferralRedirectComponent },
    { path: 'r/:affiliateCode', component: ReferralRedirectComponent },
    // auth callbacks
    { path: 'token-callback/:token', component: AuthTokenCallbackComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
	//
}
