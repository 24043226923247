import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CriticalWarning, WarningsResponse } from 'leetify-shared-utils/dto';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CriticalWarningsService {
	private criticalWarningsSource = new Subject<CriticalWarning[]>();
	public criticalWarnings$ = this.criticalWarningsSource.asObservable();

	public constructor(private readonly http: HttpClient) {
		//
	}

	private handleWarnings(criticalWarningsResponse: WarningsResponse): CriticalWarning[] {
		return criticalWarningsResponse.content;
	}

	public reloadWarnings(): void {
		this.http.get<WarningsResponse>(`${environment.csApiUrl}/api/critical-warnings`).subscribe((warnings: WarningsResponse) => {
			this.criticalWarningsSource.next(this.handleWarnings(warnings));
		});
	}
}
