import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { NotificationResponse } from 'leetify-shared-utils/dto';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  // todo rename type
  private readonly notificationSource = new Subject<NotificationResponse>();
  public readonly notifications$ = this.notificationSource.asObservable();

  public constructor(
    protected readonly http: HttpClient,
  ) {
  }

  public reloadUserNotifications(): void {
    this.http.get<NotificationResponse>(`${environment.homeApiUrl}/api/posts/v1/notifications`, { headers: AuthService.getHeaders() }).subscribe(
      (notificationResponse: NotificationResponse) => {
        this.notificationSource.next(notificationResponse);
        return notificationResponse;
      },
      (e) => {
        console.log(e);
        return null;
      },
    );
  }

  public async markNotificationsAsRead() {
    try {
      await this.http.post(`${environment.homeApiUrl}/api/posts/v1/notifications/read`, {}, { headers: AuthService.getHeaders() }).toPromise();
    } catch (err) {
      console.error(err);
    }
  }
}
