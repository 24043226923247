import moment from 'moment';
import { S3_BASE_URL } from 'src/constants';
import { ParticipantDTO } from '../services/games.service';

export class ViewHelper {
	static roleOrder = {
		top: 1,
		jungle: 2,
		middle: 3,
		adc: 4,
		support: 5,
	};

	public static readonly PROFILE_PICTURE_FALLBACK = 'assets/images/anon.png';

	static sortByRole(team: ParticipantDTO[]) {
		return team.sort((a, b) => {
			return ViewHelper.roleOrder[a.role] - ViewHelper.roleOrder[b.role];
		});
	}

	static getAssetUrl(assetType: string, asset: string | number): string | null {
		if (!asset || !assetType) return null;

		let urlToEncode: string = '';

		if (assetType === 'champion') {
			urlToEncode = `${S3_BASE_URL}${asset}`;
		} else if (assetType === 'championIcon') {
			const filename = typeof asset === 'string' ? asset.split('/').pop().split('.')[0] : asset;
			const filetype = 'jpg';
			urlToEncode = `${S3_BASE_URL}/champion/tiles/${filename}.${filetype}`;
		} else if (assetType === 'championLoading') {
			const filetype = 'jpg';
			urlToEncode = `${S3_BASE_URL}/champion/loading/${asset}.${filetype}`;
		} else if(assetType === 'centered') {
			const filetype = 'jpg';
			urlToEncode = `${S3_BASE_URL}/champion/centered/${asset}.${filetype}`;
		}

		else {
			urlToEncode = `${S3_BASE_URL}/${assetType}/${asset}.png`;
		}

		// Applying default options for now, will make a smol util for it later
		return `https://img.leetify.com/_/rs:fill:500:500/${btoa(urlToEncode)}`;
	}

	static formatGameDuration(seconds: number): string {
		const minutes = Math.floor(seconds / 60);
		const leftoverSeconds = seconds - minutes * 60;

		const minutesWithLeadingZero = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const leftoverSecondsWithLeadingZero = leftoverSeconds < 10 ? `0${leftoverSeconds}` : `${leftoverSeconds}`;

		return `${minutesWithLeadingZero}:${leftoverSecondsWithLeadingZero}`;
	}

	static roundToNumber(num: number, roundTo: number): number {
		return Math.ceil(num / roundTo) * roundTo;
	}

	static formatNumbersAsK(number: number): string {
		if (number >= 1000) {
			const dividedNumber = (number / 1000).toFixed(1);
			return `${dividedNumber}k`;
		}
		return number.toString();
	}

	static formatLPChange(number: number): string {
		if (number === 0 || !number) return '-';

		const sign = number > 0 ? '+' : '';

		return sign + String(number);
	}

	public static getLPChangeTextColorClass(lpChange: number): string {
		if (lpChange === 0 || !lpChange) return '';
		if (lpChange < 0) return 'text-poor';
		return 'text-great';
	}

	static getRankIcon(rank: string): string {
		switch (rank) {
			case 'UNRANKED':
				return 'assets/images/rank-icons/unranked.svg';
			case 'BRONZE':
				return 'assets/images/rank-icons/bronze.svg';
			case 'SILVER':
				return 'assets/images/rank-icons/silver.svg';
			case 'GOLD':
				return 'assets/images/rank-icons/gold.svg';
			case 'PLATINUM':
				return 'assets/images/rank-icons/platinum.svg';
			case 'EMERALD':
				return 'assets/images/rank-icons/emerald.svg';
			case 'DIAMOND':
				return 'assets/images/rank-icons/diamond.svg';
			case 'MASTER':
				return 'assets/images/rank-icons/master.svg';
			case 'GRANDMASTER':
				return 'assets/images/rank-icons/grandmaster.svg';
			case 'IRON':
				return 'assets/images/rank-icons/iron.svg';
			case 'CHALLENGER':
				return 'assets/images/rank-icons/challenger.svg';
		}
	}

	static getRankText(rank: string, tier: string): string {
		let rankText = '';
		let rankNumberText = '';
		switch (rank) {
			case 'I':
				rankNumberText = '1';
				break;
			case 'II':
				rankNumberText = '2';
				break;
			case 'III':
				rankNumberText = '3';
				break;
			case 'IV':
				rankNumberText = '4';
				break;
		}
		switch (tier) {
			case 'CHALLENGER':
				rankText = 'Challenger';
				break;
			case 'GRANDMASTER':
				rankText = 'Grandmaster';
				break;
			case 'MASTER':
				rankText = 'Master';
				break;
			case 'DIAMOND':
				rankText = 'Diamond ' + rankNumberText;
				break;
			case 'EMERALD':
				rankText = 'Emerald ' + rankNumberText;
				break;
			case 'PLATINUM':
				rankText = 'Platinum ' + rankNumberText;
				break;
			case 'GOLD':
				rankText = 'Gold ' + rankNumberText;
				break;
			case 'SILVER':
				rankText = 'Silver ' + rankNumberText;
				break;
			case 'BRONZE':
				rankText = 'Bronze ' + rankNumberText;
				break;
			case 'IRON':
				rankText = 'Iron ' + rankNumberText;
				break;
			case 'UNRANKED':
				rankText = 'Unranked';
				break;
		}
		return rankText;
	}

	static getRoleIcon(role: string): string {
		switch (role) {
			case 'top':
				return 'assets/images/role-icons/Top.svg';
			case 'middle':
				return 'assets/images/role-icons/Mid.svg';
			case 'adc':
				return 'assets/images/role-icons/Bot.svg';
			case 'support':
				return 'assets/images/role-icons/Support.svg';
			case 'jungle':
				return 'assets/images/role-icons/Jungle.svg';
			case 'all':
				return 'assets/images/role-icons/All.svg';
		}
	}

	static getRoleIcons(): string[] {
		return [this.getRoleIcon('top'), this.getRoleIcon('jungle'), this.getRoleIcon('middle'), this.getRoleIcon('adc'), this.getRoleIcon('support')];
	}

	static getTimeSince(gameTime: Date): string {
		const currentTime = moment();
		const difference = currentTime.diff(gameTime, 'hours');

		let returnString: string;

		if (difference > 24) {
			returnString = `${currentTime.diff(gameTime, 'days')} days`;
		} else if (difference >= 1) {
			returnString = `${difference} hours`;
		} else {
			returnString = 'less than an hour';
		}

		return returnString;
	}

	static formatTimestampMinuteSeconds(timestamp: number) {
		const minutes = Math.floor(timestamp / 60);
		const seconds = Math.floor(timestamp % 60);

		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	}

	public static onProfilePictureErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, ViewHelper.PROFILE_PICTURE_FALLBACK);
	}

	public static onImageErrored(e: ErrorEvent, fallback: string): void {
		if (e.target && e.target instanceof HTMLImageElement && e.target.getAttribute('src') !== fallback) {
			e.target.setAttribute('src', fallback);
		}
	}
}
