import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { socialMediaLinks } from 'src/constants';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { Router } from '@angular/router';

interface MenuEntry {
	icon: Icon;
	label: string;
	route: string;
	routerLink?: string;
	href?: string;
}
interface MenuGame {
	name: string;
	icon: Icon;
	slug: string;
	prefix: string;
	isExpanded: boolean;
	entries: MenuEntry[];
}

@Component({
	selector: 'leetify-common-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
})
export class CommonNavComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly isBrowser: boolean;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly socialMediaLinks = socialMediaLinks;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private readonly router: Router,
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	// Bit of a hack right now, but TODO: improve
	protected referralMenu: MenuEntry = {
		icon: Icon.NAV_REFERRALS,
		label: 'Referrals',
		route: '/referrals',
	};

	protected homeMenu: MenuEntry = {
		icon: Icon.NAV_HOME,
		label: 'Home',
		route: '/home',
	};

	protected readonly menuGames: MenuGame[] = [
		{
			name: 'CS2',
			slug: 'cs2',
			icon: Icon.NAV_CS2_LOGO,
			prefix: '/app',
			isExpanded: false,
			entries: [
				{
					icon: Icon.NAV_DASHBOARD,
					label: 'Dashboard',
					route: '',
				},
				{
					icon: Icon.NAV_MATCHES,
					label: 'Matches',
					route: '/matches/list',
				},
				{
					icon: Icon.NAV_SESSIONS,
					label: 'Sessions',
					route: '/matches/session',
				},
				{
					icon: Icon.NAV_CLUBS,
					label: 'My Clubs',
					route: '/clubs',
				},
				{
					icon: Icon.NAV_GENERAL,
					label: 'General',
					route: '/general',
				},
				{
					icon: Icon.NAV_FOCUS_AREAS,
					label: 'Focus areas',
					route: '/focus-areas',
				},
				{
					icon: Icon.NAV_MAP,
					label: 'Maps',
					route: '/maps',
				},
				{
					icon: Icon.NAV_AIM,
					label: 'Aim',
					route: '/aim',
				},
				{
					icon: Icon.NAV_UTILITY,
					label: 'Utility',
					route: '/utility',
				},
				{
					icon: Icon.NAV_PRACTICE_SERVERS,
					label: 'Practice Servers',
					route: '/servers',
				},
			],
		},
		{
			name: 'LoL',
			slug: 'lol',
			prefix: '/lol',
			icon: Icon.NAV_LOL_LOGO,
			isExpanded: false,
			entries: [
				{
					icon: Icon.NAV_MATCHES,
					label: 'Matches',
					route: '/matches/list',
				},
			],
		},
	];

	public ngOnInit(): void {
		const path = window.location.pathname;

		for (const menuGame of this.menuGames) {
			const pathPrefixed = path.startsWith(menuGame.prefix);

			if(pathPrefixed && menuGame.prefix === '/app') {
				this.referralMenu.routerLink = '/app/referrals';
			} else {
				this.referralMenu.href = '/app/referrals';
			}

			for (const entry of menuGame.entries) {
				let correctPath = menuGame.prefix + entry.route;

				if(path.startsWith('/lol') && menuGame.slug === 'lol') {
					correctPath = entry.route;
				}

				if (pathPrefixed) {
					entry.routerLink = correctPath;
				} else {
					entry.href = correctPath;
				}
			}
		}

		const isOnHome = path.startsWith('/home');

		if(isOnHome) {
			this.homeMenu.routerLink = '/';
		} else {
			this.homeMenu.href = '/home';
		}

		// Set iniital expanded menu item
		try {
			const storedExpandedMenus = window.localStorage.getItem('expandedMenus');

			let parsedExpandedMenus;

			if (!storedExpandedMenus) {
				const newStoredEntry = [];

				newStoredEntry.push('cs2');
				
				if (path.startsWith('/lol')) {
					newStoredEntry.push('lol');
				}

				window.localStorage.setItem('expandedMenus', JSON.stringify(newStoredEntry));

				parsedExpandedMenus = newStoredEntry;
			} else {
				parsedExpandedMenus = JSON.parse(storedExpandedMenus);
			}

			if (parsedExpandedMenus && !Array.isArray(parsedExpandedMenus)) {
				// Throw so that we can reset the localStorage state
				throw new Error('Invalid expanded menus');
			}

			for (const menu of parsedExpandedMenus) {
				const menuEntry = this.menuGames.find((menuGame) => menuGame.slug === menu);

				if (menuEntry) {
					menuEntry.isExpanded = true;
				}
			}
		} catch (e) {
			// Uh-oh, we failed. Let's reset the localStorage state
			window.localStorage.removeItem('expandedMenus');
		}
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	public toggleSubMenu(menuName: string): void {
		const menu = this.menuGames.find((menuGame) => menuGame.slug === menuName);

		if (!menu) {
			return;
		}

		menu.isExpanded = !menu.isExpanded;

		const expandedMenus = this.menuGames.filter((menuGame) => menuGame.isExpanded).map((menuGame) => menuGame.slug);

		window.localStorage.setItem('expandedMenus', JSON.stringify(expandedMenus));
	}
}
