import { PrivacySettingsHelper } from './privacy-settings.helper';

export class SpectatingHelper {
    public static getSpectateButtonTooltip(isSensitiveDataVisible: boolean, leetifyUserId: string, isProPlan: boolean): string {
        if (!isProPlan) return 'Spectate is a Pro plan feature.';
        return isSensitiveDataVisible ? 'Spectate' : PrivacySettingsHelper.getSpectateTooltip(isSensitiveDataVisible, leetifyUserId);
    }

    public static isSpectateButtonDisabled(isSensitiveDataVisible: boolean, isProPlan: boolean): boolean {
        if (!isProPlan) return true;
        if (!isSensitiveDataVisible) return true;

        return false;
    }
}
