import moment from 'moment';

export class NotificationsHelper {

  static getNotificationTime(receivedAt: Date): string {
    const currentTime = moment();
    const difference = currentTime.diff(receivedAt, 'hours');

    let returnString: string;

    if (difference >= 720) {
      returnString = `over 30 days ago`;
    } else if (difference >= 48) {
      returnString = `${currentTime.diff(receivedAt, 'days')} days ago`;
    } else if (difference >= 24) {
      returnString = `${currentTime.diff(receivedAt, 'days')} day ago`;
    } else if (difference > 1) {
      returnString = `${difference} hours ago`;
    } else if (difference == 1) {
      returnString = `${difference} hour ago`;
    } else if (currentTime.diff(receivedAt, 'minutes') <= 1) {
      returnString = 'just now';
    } else {
      returnString = `${currentTime.diff(receivedAt, 'minutes')} minutes ago`;
    }

    return returnString;
  }
}
