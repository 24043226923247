import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../environments/environment';
import { CriticalWarningsService } from 'src/components/services/critical-warnings/critical-warnings.service';
import {
  CriticalWarning,
  Follow,
  NotificationResponse,
  Notification
} from '../../../../leetify-shared-utils/dto';
import { FollowService } from 'src/components/services/follow-request/follow.service';
import { ViewHelper } from 'src/app/helpers/view.helper';
import { NotificationsService } from '../../services/notifications/notifications.service';

@Component({
	selector: 'leetify-common-topnav',
	templateUrl: './topnav.component.html',
	styleUrls: ['./topnav.component.scss'],
})
export class CommonTopnavComponent implements OnChanges, OnDestroy, OnInit {
	@Output() protected readonly toggleNavigation = new EventEmitter<boolean | void>();

	@Input() protected navigationActive = false;

	protected focusSearchInput: Subject<boolean> = new Subject<boolean>();
	protected focusSearchInput$ = this.focusSearchInput.asObservable();
	protected searchActive = false;
	protected user: User;

	protected proButtonClass = 'btn-primary';
	protected proButtonText = 'Get Pro';
	protected criticalWarnings: CriticalWarning[] = [];
	protected followRequests: Follow[] = [];
	protected profilePictureUrl: string;
  protected notifications: Notification[];
  protected hasUnreadNotifications: boolean = false;

	protected readonly ngUnsubscribe = new Subject<void>();

	public readonly onProfilePictureErrored = ViewHelper.onProfilePictureErrored;

	public constructor(
		// protected readonly clubsService: ClubsService,
		protected readonly userService: UserService,
		protected readonly criticalWarningsService: CriticalWarningsService,
		protected readonly followService: FollowService,
    protected readonly notificationsService: NotificationsService,
	) {
		//
	}

	protected onLogin() {
		// TODO: Redirect based on current page
		window.location.href = `${environment.csFrontendBaseUrl}/auth/login`;
	}

	protected onSignup() {
		// TODO: Redirect based on current page
		window.location.href = `${environment.csFrontendBaseUrl}/auth/signup`;
	}

	public toggleNav(e: MouseEvent): void {
		e.preventDefault();
		this.toggleNavigation.emit();
	}

	public toggleSearch(e?: MouseEvent, value?: boolean): void {
		if (e) e.preventDefault();

		this.searchActive = value === undefined ? !this.searchActive : value;

		if (this.searchActive) {
			document.body.classList.add('--mobile-search-active');
			this.toggleNavigation.emit(false);
			this.focusSearchInput.next(true);
		} else {
			document.body.classList.remove('--mobile-search-active');
		}
	}

	private setProButton(user: User): void {
		if (!user) return;

		this.proButtonClass = 'btn-primary';
		this.proButtonText = 'Get Pro';

		if (user.isProPlan) {
			this.proButtonClass = 'btn-primary';
			this.proButtonText = 'My Pro';
		}

		if (user.isCollector) {
			this.proButtonClass = 'btn-founder';
		}
	}

	public setUser(user: User): void {
		this.user = user;

		this.setProButton(user);

		if(user) {
			// @ts-expect-error - home uses leetifyUserId, LoL and CS uses id
			this.profilePictureUrl = this.userService.getProfilePictureUrl(user.leetifyUserId || user.id);

			this.criticalWarningsService.criticalWarnings$
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((criticalWarnings) => this.handleCriticalWarnings(criticalWarnings));

			this.criticalWarningsService.reloadWarnings();

			this.followService.followRequests$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((follow) => {
				this.handleFollow(follow.users);
			});

			this.followService.reloadUserFollowRequests();

			this.notificationsService.notifications$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((notification) => {
			  this.handleNotifications(notification);
			});
			this.notificationsService.reloadUserNotifications();
		}
	}

	protected goToPro() {
		window.location.href = `${environment.csFrontendBaseUrl}/app/pro`;
	}

	protected goToProfile() {
		window.location.href = `${environment.csFrontendBaseUrl}/app/profile`;
	}

	protected handleCriticalWarnings(warnings: CriticalWarning[]) {
		this.criticalWarnings = warnings;
	}

	protected handleFollow(follows: Follow[]) {
    this.followRequests = follows;
	}

  protected handleNotifications(notifications: NotificationResponse) {
    if (notifications) {
      this.notifications = [...(notifications.unreadNotificationsResponse || []), ...(notifications.readNotificationsResponse || [])];

      for (const n of this.notifications) {
        n.data.avatar = this.getProfilePictureUrl(n.data.sender_id);
      }
      this.hasUnreadNotifications = notifications.unreadNotificationsResponse.length > 0 ;
    }
  }

  protected getProfilePictureUrl(leetifyUserId: string): string {
      if (!leetifyUserId) return ViewHelper.PROFILE_PICTURE_FALLBACK

      return `${environment.csApiUrl}/api/user/profile-picture/${leetifyUserId}`;
  }

	public ngOnInit(): void {
		this.userService.reloadUser();
		this.setUser(this.userService.user);
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.setUser(user));

		/*this.clubsService.selectedClub$.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((selectedClub: ClubListItemInfo) => this.selectedClub = selectedClub);*/
	}

	public ngOnChanges(changes: SimpleChanges): void {
		// close mobile search when activating mobile nav
		if (changes.navigationActive && changes.navigationActive.currentValue) this.searchActive = false;
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
