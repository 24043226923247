import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { fromEvent, Observable, Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { SearchResultDTO } from 'leetify-shared-utils/dto';
import { SearchService } from 'src/components/services/search/search.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { SpectatingService } from 'src/components/services/spectating/spectating.service';
import { SpectatingHelper } from 'src/components/helpers/spectating.helper';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { environment } from '../../../../environments/environment';

interface SearchResultViewModel extends SearchResultDTO {
  spectateTooltip: string;
  spectateDisabled: boolean;
  showCompare: boolean;
  showSpectate: boolean;
  showProfile: boolean;
  lolUser: boolean;
  lolUsername: string;
  steamUsername: string;
}

@Component({
  selector: 'leetify-common-player-search',
  templateUrl: './player-search.component.html',
  styleUrls: ['./player-search.component.scss'],
})
export class CommonPlayerSearchComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() protected readonly focusSearchInput$: Observable<boolean>;
  @Output() protected readonly closeSearch = new EventEmitter<void>();
  @ViewChild('input', { static: true }) protected readonly input: ElementRef;
  @ViewChild('inputContainer', { static: true }) protected readonly inputContainer: ElementRef;
  @ViewChild('resultsContainer', { static: true }) protected readonly resultsContainer: ElementRef;

  protected Icon = Icon;

  protected readonly isBrowser: boolean;
  protected readonly ngUnsubscribe = new Subject<void>();

  protected isSearchOpen = false;
  protected recentSearchResults: SearchResultViewModel[] = [];
  protected requestInProgress = false;
  protected searchResults: SearchResultViewModel[] = [];
  protected searchTerm = '';
  protected user: User;

  public constructor(
    @Inject(PLATFORM_ID) platformId: Record<string, any>,
    protected readonly router: Router,
    protected readonly searchService: SearchService,
    protected readonly spectatingService: SpectatingService,
    protected readonly userService: UserService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  protected clearSearch(): void {
    this.searchTerm = '';
    this.searchResults = [];
  }

  protected searchFocused(): void {
    this.isSearchOpen = true;
  }

  protected async performSearch(query: string): Promise<void> {
    if (!this.user) return;

    // Reset search results before search starts
    this.searchResults = [];
    if (!query) return;

    this.requestInProgress = true;

    const csResults = await this.searchService.searchCS(query).catch(() => []);
    const lolResults = await this.searchService.searchLoL(query).catch(() => []);

    const searchResults: { [key: string]: SearchResultViewModel } = {};

    csResults.forEach((r) => {
      searchResults[r.userId] = {
        ...r,
        spectateToolTip: SpectatingHelper.getSpectateButtonTooltip(r.isSensitiveDataVisible, r.userId, this.user.isProPlan),
        spectateDisabled: SpectatingHelper.isSpectateButtonDisabled(r.isSensitiveDataVisible, this.user.isProPlan),
        steamUsername: r.nickname,
        showSpectate: true,
        showCompare: false,
        showProfile: true,
      };
    });

    lolResults.forEach((r) => {
      if (searchResults[r.userId]) {
        searchResults[r.userId] = {
          ...r,
          ...searchResults[r.userId],
          lolUsername: r.nickname,
        };
      } else {
        searchResults[r.userId] = {
          ...r,
          showSpectate: false,
          showCompare: false,
          showProfile: false,
          lolUsername: r.nickname,
        };
      }
    });

    this.searchResults = Object.values(searchResults);

    this.requestInProgress = false;
  }

  protected spectate(result: SearchResultDTO): void {
    if (!result.isSensitiveDataVisible) return;

    this.closeSearch.emit();
    this.spectatingService.startSpectating(result.userId);
    this.saveRecentResult(result);
  }

  protected saveRecentResult(result: SearchResultDTO): void {
    this.searchService.saveRecentSearchResult(result);
  }

  protected removeRecentResult(result: SearchResultDTO): void {
    this.searchService.removeRecentSearchResult(result);
  }

  protected handleRecentResults(recentSearchResults: SearchResultDTO[]): void {
    this.recentSearchResults = recentSearchResults.map((r) => {
      const result = r as SearchResultViewModel;
      result.spectateTooltip = SpectatingHelper.getSpectateButtonTooltip(r.isSensitiveDataVisible, r.userId, this.user.isProPlan);
      result.spectateDisabled = SpectatingHelper.isSpectateButtonDisabled(r.isSensitiveDataVisible, this.user.isProPlan);
      return result;
    });
  }

  protected handleUser(user: User): void {
    this.user = user;
    if (!user) return;

    if (this.isBrowser) this.searchService.reloadRecentSearchResults();
  }

  @HostListener('document:click', ['$event'])
  protected onClick(e: PointerEvent): void {
    if (this.inputContainer.nativeElement.contains(e.target) || this.resultsContainer.nativeElement.contains(e.target)) return;

    this.isSearchOpen = false;
    this.input.nativeElement.blur();
  }

  protected goToProfile(result: SearchResultDTO) {
    window.location.href = `${environment.csFrontendBaseUrl}/app/profile/${result.steam64Id}`;
  }

  protected goToCompare(result: SearchResultDTO) {
    window.location.href = `${environment.csFrontendBaseUrl}/app/compare/${this.user.steam64Id}/${result.steam64Id}`;
  }

  protected goToLogin() {
    window.location.href = `${environment.csFrontendBaseUrl}/auth/login?home=1`;
  }

  protected goToSignup() {
    window.location.href = `${environment.csFrontendBaseUrl}/auth/signup?home=1`;
  }

  public ngOnInit(): void {
    this.closeSearch.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => (this.isSearchOpen = false));
    this.focusSearchInput$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.input.nativeElement.focus());
    this.searchService.recentSearchResults$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((recentSearchResults) => this.handleRecentResults(recentSearchResults));
    this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));

    // close mobile search after clicking any link
    this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(() => this.closeSearch.emit());

    this.handleUser(this.userService.user);
  }

  public ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: KeyboardEvent) => {
        if (e.key !== 'Escape') return;

        this.input.nativeElement.blur();
        this.isSearchOpen = false;
        this.closeSearch.emit();
      });

    fromEvent(this.input.nativeElement, 'input')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.requestInProgress = !!this.searchTerm;
      });

    fromEvent(this.input.nativeElement, 'input')
      .pipe(takeUntil(this.ngUnsubscribe), filter(Boolean), debounceTime(500), distinctUntilChanged())
      .subscribe((textEvent: any) => {
        this.performSearch(textEvent.target.value);
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
