<div class="row justify-content-center pt-5">
	<div class="col-md-8 col-lg-6 col-xl-5 pt-5">
		<div class="card">
			<div class="card-body p-5">
				<div class="text-center w-75 m-auto">
					<a routerLink="/" class="logo text-center">
						<img src="assets/images/leetify-logo-primary-white.svg" alt="Leetify" height="30">
					</a>
				</div>

				<div class="status">
					<img src="assets/images/spinner.svg" class="spinner" />
					We're logging you in – just a moment...
				</div>
			</div>
		</div>
	</div>
</div>
