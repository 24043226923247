<a href="/home" class="sidebar-logo">
	<img src="assets/images/leetify-logo-primary-white.svg" alt="Leetify">
</a>

<leetify-common-topnav [navigationActive]="navigationActive" (toggleNavigation)="toggleNavigation($event)"></leetify-common-topnav>

<leetify-common-nav [ngClass]="{ '--active': navigationActive }"></leetify-common-nav>

<main class="container-fluid" [ngClass]="{ '--muted': navigationActive }">
	<router-outlet></router-outlet>

	<div class="mobile-nav-darken-overlay" [ngClass]="{ '--active': navigationActive }" (click)="onMainContentClick($event)"></div>
</main>
