<div [ngClass]="isRead ? 'follow-row' : 'follow-row unread'">
  <img class="avatar"
       [src]="followRequest.avatar || 'assets/images/anon.png'"
       (error)="onProfilePictureErrored($event)"
       alt="picture"/>
  <div class="follow-text-container">
    <div class="in-line">
      <div class="nickname-text bold" [class]="isNameTooLong() ? 'ellipsis' : ''" (click)="goToProfile(followRequest.sender_id)">{{ followRequest.nickname }}</div>
      <div class="follow-text"> sent a follow request</div>
    </div>
    <div class="in-line">
      <div class="subtitle">{{ calculateMinutesAgo() }}</div>
    </div>
    <div class="in-line" style="gap: 16px; margin-top: 8px">
      <div *ngIf="!followBackSent && followRequest.status" class="follow-text">Request {{ followRequest.status }}</div>
      <div *ngIf="!followRequest.status && !followBack" class="follow-button ignore text ig" (click)="ignoreRequest()">Ignore</div>
      <div *ngIf="!followRequest.status &&!followBack" class="follow-button accept text acc" (click)="acceptRequest()">Accept</div>
      <div *ngIf="!followBackSent && followBack" class="follow-button accept text acc" (click)="sendFollowRequestBack()">Follow back</div>
      <div *ngIf="followBackSent" class="follow-text">Request sent</div>
    </div>
  </div>
</div>
