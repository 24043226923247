import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FollowService } from 'src/components/services/follow-request/follow.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
	selector: 'leetify-common-follow-button',
	templateUrl: './follow-button.component.html',
	styleUrls: ['./follow-button.component.scss'],
})
export class CommonFollowButtonComponent implements OnDestroy, OnInit {
  @Input() protected readonly userId: string;
  @Input() protected readonly outline: boolean = true;
  @Input() protected readonly userSearch?: boolean = false;
  protected text: string;
  protected savedStatus: string;
  protected isHovering = false;

  public constructor(private followService: FollowService, private http: HttpClient) {}

  // TODO should probably use enums
  protected getClass(): string {
  	switch (this.savedStatus) {
  		case 'pending':
  			return 'Requested';
  		case 'accepted':
  			return 'Following';
  		case 'rejected':
  			return 'Requested';
  		default:
  			return 'Follow';
  	}
  }

  protected setDefaultText() {
	switch (this.savedStatus) {
		case 'pending':
	  case 'rejected':
			this.text = this.userSearch ? 'Follow requested' : 'Requested';
			break;
		case 'accepted':
			this.text = 'Following'
			break;
		default:
			this.text = 'Follow';
	}
  }

  protected onLeave() {
  	this.isHovering = false;
	this.setDefaultText();
  }

  protected onHover() {
  	this.isHovering = true;
  	switch (this.savedStatus) {
  		case 'pending':
		case 'rejected':
  			this.text = this.userSearch ? 'Cancel request' : 'Cancel';
  			break;
  		case 'accepted':
  			this.text = 'Unfollow';
  			break;
  		default:
  			this.text = 'Follow';
  	}
  }

  protected async followAction() {
  	switch (this.savedStatus) {
  		case 'pending': {
  			// if user clicks on a pending request -> request should be canceled
  			await this.followService.unfollowOrCancelRequest(this.userId);
  			// texts should update to follow
  			this.savedStatus = 'follow';
			this.setDefaultText();
  			break;
  		}
  		case 'accepted': {
  			// if user clicks on accepted -> should unfollow
  			await this.followService.unfollowOrCancelRequest(this.userId);
  			// text should change to follow
  			this.savedStatus = 'follow';
			this.setDefaultText();
  			break;
  		}
  		case 'rejected': {
  			// if user clicks on denied (which they see as pending -> request should be canceled
  			await this.followService.unfollowOrCancelRequest(this.userId);

  			// text should change to follow
  			this.savedStatus = 'follow';
			this.setDefaultText();
  			break;
  		}
  		default: {
  			// send follow request
  			await this.followService.sendRequest(this.userId);

  			// text should change to pending
  			this.savedStatus = 'pending';
			this.setDefaultText();
  		}
  	}
  	this.onLeave();
  }

  ngOnInit(): void {
  	this.http
  		.get(`${environment.homeApiUrl}/api/follow/v1/${this.userId}/status`, { headers: AuthService.getHeaders() })
  		.subscribe((status: { followed: { status: string }}) => {
  			this.savedStatus = status?.followed?.status || 'none';
  			this.onLeave();
  		});
  }

  ngOnDestroy(): void {
  	//
  }
}
