import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CriticalWarningStyle } from '../../../../../leetify-shared-utils/enums';
import { CriticalWarning, Follow, Notification, } from '../../../../../leetify-shared-utils/dto';
import { environment } from '../../../../environments/environment';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { ViewHelper } from '../../../../app/helpers/view.helper';
import { NotificationsHelper } from '../../../helpers/notifications.helper';

@Component({
  selector: 'leetify-common-notifications-component',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})

export class CommonNotificationsComponent implements OnDestroy, OnInit {
  @Input() criticalWarnings: CriticalWarning[];
  @Input() followRequests: Follow[] = [];
  @Input() notifications: Notification[];
  @Input() hasUnreadNotifications: boolean;
  public readonly onProfilePictureErrored = ViewHelper.onProfilePictureErrored;
  protected readonly CriticalWarningStyle = CriticalWarningStyle;
  protected isActive: boolean = false;
  protected serverIconPath: string;
  protected warningIconPath: string;
  protected serverState: string;
  protected time: string;
  protected isFollowRequestsTabActive: boolean = false;
  protected isSocialNotificationsTabActive: boolean = false;
  protected isAllTabActive: boolean = true;
  protected notificationsRead: boolean = false;
  // to check only mark notifications as read on the 2nd open
  protected wereNotificationsOpened = false;
  protected followRequestsCount = 0;
  protected socialNotificationsCount = 0;
  protected allNotificationsCount = 0;

  private ngUnsubscribe = new Subject<void>();

  public constructor(
    private _eref: ElementRef,
    protected notificationsService: NotificationsService,
  ) {
    //
  }

  public onClick(event: MouseEvent): void {
    if (!this._eref.nativeElement.contains(event.target)) { // or some similar check
      this.isActive = false;
    }
  }

  protected async toggleDropdown() {
    // first time opening notificationsRead will be false
    if (this.notificationsRead) {
      this.wereNotificationsOpened = true;
    }
    this.isActive = !this.isActive;

    // mark as read
    await this.notificationsService.markNotificationsAsRead();
    this.notificationsRead = true;

    if (this.notifications) {
      this.notifications.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

      this.followRequestsCount = this.getNumberOfSocialNotifications('follow');
      this.socialNotificationsCount = this.getNumberOfSocialNotifications('like');
      this.allNotificationsCount = this.getNumberOfSocialNotifications('all');
    }
  }

  protected switchTab(tab: 'follow' | 'social' | 'all') {
    this.isFollowRequestsTabActive = tab === 'follow';
    this.isSocialNotificationsTabActive = tab === 'social';
    this.isAllTabActive = tab === 'all';
  }

  private getOverallState(activeCriticalWarnings: CriticalWarning[]) {
    if (activeCriticalWarnings.some((cw) => cw.style === CriticalWarningStyle.DANGER)) return CriticalWarningStyle.DANGER;
    if (activeCriticalWarnings.some((cw) => cw.style === CriticalWarningStyle.WARNING)) return CriticalWarningStyle.WARNING;
    return CriticalWarningStyle.SUCCESS;
  }

  protected getBellBackgroundIcon() {
    if (this.notificationsRead) {
      // if notifications were read show no bell
      return 'assets/icons/notifications-bell.svg';
    } else if (this.hasUnreadNotifications) {
      // if notifications were not read but there are notifications show bell
      return 'assets/icons/notifications-bell-active.svg'
    }
    // else show no bell
    return 'assets/icons/notifications-bell.svg';
  }

  private getServerStatusIcon(serverStatus: string) {
    if (serverStatus === CriticalWarningStyle.SUCCESS) return 'assets/icons/server-status-no-error.svg';
    if (serverStatus === CriticalWarningStyle.DANGER) return 'assets/icons/server-status-error.svg';
    if (serverStatus === CriticalWarningStyle.WARNING) return 'assets/icons/server-status-warning.svg';
    return '';
  }

  protected getServerStatusType(serverState: string) {
    return serverState;
  }

  protected getWarningIcon(serverStatus: string) {
    if (serverStatus === CriticalWarningStyle.WARNING) return 'assets/icons/warning-sign.svg';
    if (serverStatus === CriticalWarningStyle.DANGER) return 'assets/icons/error-sign.svg';
    return '';
  }

  protected isNameTooLong(username: string) {
    if (username) return username.length > 17;
    return false;
  }

  protected calculateMinutesAgo(createdAt: Date) {
    return NotificationsHelper.getNotificationTime(createdAt);
  }

  protected getNumberOfSocialNotifications(type: string) {
    let count = 0;

    if (this.notifications) {
      // sort by created at
      if (type === 'follow') {
        count = this.notifications.filter(notification => notification.data.type === 'follow').length;
      } else if (type === 'like') {
        count = this.notifications.filter(notification => notification.data.type === 'like').length;
      } else {
        count = this.notifications.length;
      }
    }

    return count;
  }

  protected goToPost(gameName: string, matchId: string, leetifyUserId: string) {
    window.location.href = `${environment.frontendBaseUrl}/home/feed/${gameName}/${matchId}/${leetifyUserId}`;
  }

  protected goToProfile(senderId: string) {
    window.location.href = `${environment.frontendBaseUrl}/app/profile/${senderId}`;
  }

  ngOnInit(): void {
    this.serverState = this.getOverallState(this.criticalWarnings);

    this.serverIconPath = this.getServerStatusIcon(this.serverState);
    this.warningIconPath = this.getWarningIcon(this.serverState);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
