import moment from 'moment';
import { AvailableProgressReportDTO } from './dto';
import { ProgressReportQuarter } from './enums';

export class ProgressReportsHelper {
	public static getThreeLetterMonthNameRange(
		quarter: ProgressReportQuarter
	): 'Jan - Jun' | 'Jul - Dec' | 'Jan - Mar' | 'Apr - Jun' | 'Jul - Sep' | 'Oct - Dec' {
		switch (quarter) {
			case ProgressReportQuarter.H1:
				return 'Jan - Jun';
			case ProgressReportQuarter.H2:
				return 'Jul - Dec';

			case ProgressReportQuarter.Q1:
				return 'Jan - Mar';
			case ProgressReportQuarter.Q2:
				return 'Apr - Jun';
			case ProgressReportQuarter.Q3:
				return 'Jul - Sep';
			case ProgressReportQuarter.Q4:
				return 'Oct - Dec';
		}
	}

	public static getQuarterLabel(quarter: ProgressReportQuarter): string {
		switch (quarter) {
			case ProgressReportQuarter.H1:
			case ProgressReportQuarter.H2:
				return ProgressReportsHelper.getThreeLetterMonthNameRange(quarter);

			case ProgressReportQuarter.Q1:
				return 'Q1';
			case ProgressReportQuarter.Q2:
				return 'Q2';
			case ProgressReportQuarter.Q3:
				return 'Q3';
			case ProgressReportQuarter.Q4:
				return 'Q4';
		}
	}

	/** Returns all progress reports and annual recaps that are likely available for a user, based on their sign up date. Ordered newest to oldest. */
	public static getAvailableProgressReports({
		steam64Id,
		userCreatedAt,
		userId,
	}: {
		steam64Id: string;
		userCreatedAt: Date;
		userId: string;
	}): AvailableProgressReportDTO[] {
		const reports: AvailableProgressReportDTO[] = [];

		const date = moment().startOf('quarter').subtract(7, 'days'); // make the cutoff a week before the end of the current quarter (i.e. you need to have an account for 7 days within a period to see the report)

		while (date.isAfter(userCreatedAt)) {
			const year = date.year();
			const quarter = date.quarter();

			date.subtract(1, 'quarter');

			if (year === 2024 && quarter === 1) continue; // we didn't do Q1 in 2024
			if (year === 2023 && quarter === 1) continue; // we didn't do Q1 in 2023
			if (year < 2023 && quarter !== 4) continue; // we didn't do Progress Reports before 2023 (they weren't compatible with this at least)
			if (year < 2021) break; // 2020 YIRs aren't available anymore

			// we did an H1 report in 2023 instead of Q2
			if (year === 2023 && quarter === 2) {
				reports.push({ year, id: steam64Id, quarter: ProgressReportQuarter.H1 });
				continue;
			}

			switch (quarter) {
				case 1:
				case 2:
				case 3:
					reports.push({ year, id: steam64Id, quarter: `q${quarter}` as ProgressReportQuarter });
					break;

				case 4: {
					reports.push({ year, id: year >= 2023 ? steam64Id : userId, quarter: ProgressReportQuarter.ANNUAL });
					break;
				}
			}
		}

		return reports;
	}

	public static getProgressReportTitleWithoutNickname(year: number, quarter: ProgressReportQuarter): string {
		if (year === 2023 && quarter === ProgressReportQuarter.Q3) return 'Final CS:GO Progress Report';
		if (quarter === ProgressReportQuarter.ANNUAL) return `${year} Recap`;
		return `${year} ${ProgressReportsHelper.getQuarterLabel(quarter)} Progress Report`;
	}

	public static getUrlSegments(report: AvailableProgressReportDTO): (number | string)[] {
		if (report.quarter === ProgressReportQuarter.ANNUAL) return [`/${report.year}`, report.id];
		return ['/progress-report', report.year, report.quarter, report.id];
	}
}
