import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private http: HttpClient,
	) {
	}

	public static setToken(token: string): void {
		localStorage.setItem('access_token', token);
	}

	public static getToken(): string {
		return localStorage.getItem('access_token');
	}

	public static removeToken(): void {
		if (typeof localStorage !== 'undefined') localStorage.removeItem('access_token');
	}

	public logout(): any {
		AuthService.removeToken();
	}

	public isAuthenticated(): boolean {
		return !!AuthService.getToken();
	}

	public static getHeaders(): HttpHeaders {
		const token = AuthService.getToken();
		return new HttpHeaders({
		  'Authorization': `Bearer ${token}`
		});
	}
}
