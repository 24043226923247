export const environment = {
	production: false,
	dateFormat: 'YYYY-MM-DD',
	apiUrl: 'https://api.lol-staging.leetify.com',
	homeApiUrl: 'https://home-api.i-staging.leetify.com',
	lolApiUrl: 'https://api.lol-staging.leetify.com',
	frontendBaseUrl: 'https://ezmid.leetify.com',
	csFrontendBaseUrl: 'https://ezmid.leetify.com',
	csApiUrl: 'https://api.cs-staging.leetify.com',
};
